import React from 'react';
import ServiceTemplate from 'templates/ServiceTemplate';

import CrackedEarth from 'images/cracked-earth.jpg';

const bullets = [
  'CEOs and CXOs',
  'Expand your mind',
  'Expand your impact',
];

const OutvoxNetworkPage = () => (
  <ServiceTemplate
    bullets={bullets}
    excludeUrl="/outvox-network"
    imagePath={CrackedEarth}
    outText="Experience"
    title="The OutVox Network"
  >
    <div>
      <h4>Experience matters.</h4>
      <p>
        The OutVox network is comprised of CEOs and CXOs who have created, grown,
        and successfully sold technology companies.
      </p>

      <p>
        The network is called upon to address any OutVox customer challenge or concern.
      </p>
    </div>
  </ServiceTemplate>
);

export default OutvoxNetworkPage;
