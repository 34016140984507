import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import PageTemplate from 'templates/PageTemplate';
import ServiceHeader from 'components/ServiceHeader';
import ServicesList from 'components/ServicesList';

const ServiceTemplate = ({
  bullets,
  children,
  imagePath,
  metaDescription,
  outText,
  title,
  excludeUrl,
}) => (
  <PageTemplate className="service" metaDescription={metaDescription}>
    <Helmet title={title} />

    <ServiceHeader
      imagePath={imagePath}
      outText={outText}
      title={title}
      bullets={bullets}
    />

    <section className="container--narrow content">
      {children}
    </section>

    <aside className="container">
      <ServicesList excludeUrl={excludeUrl} />
    </aside>
  </PageTemplate>
);

ServiceTemplate.propTypes = {
  bullets: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  imagePath: PropTypes.string.isRequired,
  metaDescription: PropTypes.string,
  outText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excludeUrl: PropTypes.string.isRequired,
};

ServiceTemplate.defaultProps = {
  metaDescription: null,
};

export default ServiceTemplate;
