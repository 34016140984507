import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card';
import servicesArray from '../services';

const ServicesList = ({ excludeUrl }) => {
  const getServices = () => servicesArray.filter(({ url }) => url !== excludeUrl)
    .map(({
      url,
      title,
      summary,
    }) => (
      <Card
        key={url}
        title={title}
        summary={summary}
        url={url}
        linkText="Learn More"
      />
    ));

  return (
    <aside>
      <h3>Other Services</h3>

      <div className="services card--list">
        {getServices()}
      </div>
    </aside>
  );
};

ServicesList.propTypes = {
  excludeUrl: PropTypes.string,
};

ServicesList.defaultProps = {
  excludeUrl: null,
};

export default ServicesList;
