import React from 'react';
import PropTypes from 'prop-types';

const ServiceHeader = ({
  imagePath,
  outText,
  title,
  bullets,
}) => (
  <header>
    <div className="service--title">
      <h1>Out<strong>{outText}</strong></h1>
      <h2>{title}</h2>
    </div>

    <div
      className="service--heading container"
      style={{
        backgroundImage: `url(${imagePath})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        backgroundPositionY: 'center',
        backgroundSize: '60%',
        height: '400px',
        minHeight: '400px',
      }}
    >
      <div className="container service--bullets">
        <ul>
          {/* eslint-disable-next-line react/no-array-index-key */}
          {bullets.map((text, index) => <li key={index}>{text}</li>)}
        </ul>
      </div>
    </div>
  </header>
);

ServiceHeader.propTypes = {
  imagePath: PropTypes.string.isRequired,
  outText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bullets: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ServiceHeader;
